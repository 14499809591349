<template>
  <div
      class="map"
  >
    <div class="container">
      <v-img
          class="logo"
          contain
          :src="require('../assets/netry_logo_border.png')"
      ></v-img>
      <div :class="{ 'donation': iWantToDonate}" class="main">
        <div v-if="!iWantToDonate">
          <h1 class="font-weight-bold text-md-h2 text-sm-h3 pa-2">
            Netry.By
          </h1>
          <div class="slider">
            <v-img
                :lazy-src="index?images[index-1]:images[0]"
                :key="index"
                max-height="40vh"
                max-width="100%"
                :src="currentImage"
            ></v-img>
          </div>
          <h2 class="pa-1">Restoration of an burned house in Naliboki Forest / Belarus</h2>
          <p class="pa-1">
            Help us to <b>rebuild</b> the house of Ivan Mulin in Naliboki Forest, Belarus.
            <v-spacer></v-spacer>
            The house is <b>completely burned down</b> at the end of January 2021. This place, situated <b>in the heart
            of Naliboki Forest</b>, was a meeting point of
            <v-btn plain color="white" text @click="iWantToDonate=!iWantToDonate">...Read More
              <v-icon right>mdi-heart</v-icon>
            </v-btn>
          </p>
        </div>
        <div v-else style="position: relative;height:100%;overflow: hidden;">
          <iframe style="position: absolute;top:0;left: 0;width: 100%;height: 100%;" width="400" height="417.453"
                  src="https://zrzutka.pl/en/7we2aw/widget/23" frameborder="0" scrolling="no"></iframe>
          <v-btn @click="iWantToDonate=!iWantToDonate">
            <v-icon left>mdi-arrow-left-bold</v-icon>
            Go Back
          </v-btn>
        </div>
      </div>
      <div class="footer">
        <v-btn
            large
            fab
            dark
            href="https://www.youtube.com/watch?v=DkgS-R9UQzc"
            target="_blank"
        >
          <v-icon dark x-large>
            mdi-youtube
          </v-icon>
        </v-btn>
        <v-btn
            large
            fab
            dark
            href="https://wa.me/qr/R7XA44VAWNHXO1"
            target="_blank"
        >
          <v-icon dark x-large>
            mdi-whatsapp
          </v-icon>
        </v-btn>
        <v-img
            class="footer-image"
            contain
            :src="require('../assets/bortnik_logo_border.png')"
        ></v-img>
        <v-btn
            large
            fab
            dark
            href="https://www.instagram.com/netry.by/"
            target="_blank"
        >
          <v-icon dark x-large>
            mdi-instagram
          </v-icon>
        </v-btn>
        <v-btn
            large
            fab
            dark
            href="mailto:info@netry.by?subject=I want to ...&body=Hello, Ivan!"
            target="_blank"
        >
          <v-icon dark x-large>
            mdi-email
          </v-icon>
        </v-btn>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'NetryBy',
  data: () => ({
    iWantToDonate: false,
    currentImage: require('../assets/burnedHouse/1.jpeg'),
    index: 0,
    interval: null,
    images: [
      require('../assets/burnedHouse/1.jpeg'),
      require('../assets/burnedHouse/2.jpeg'),
      require('../assets/burnedHouse/3.jpeg'),
      require('../assets/burnedHouse/4.jpeg'),
      require('../assets/burnedHouse/5.jpeg'),
    ],
  }),
  methods: {
    nextImage() {
      if (this.index < 4) {
        this.index++
      } else {
        this.index = 0
      }
      this.currentImage = this.images[this.index]
    },
  },
  mounted() {
    this.interval = setInterval(this.nextImage, 3000)
  },
  beforeDestroy() {
    clearInterval(this.interval)
  },
}
</script>
<style scoped>
.map {
  background: url('../assets/map.png') no-repeat center center fixed;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
  height: 100%;
}

.container {
  max-width: 100%;
  height: 100%;
  /* Center child horizontally*/
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  padding: 0 !important;
}

.logo {
  z-index: 2;
  max-width: 120px;
  max-height: 120px;
  background-color: #272727;
  border: 5px solid #272727;
  border-radius: 50%;
  height: auto; /* So the image doesn't distort */
  margin: 10px auto;
}

.slider {
    min-height: 40vh;
  }

@media (min-width: 769px) {
  .main {
    background: rgba(0, 0, 0, 0.8);
    width: 50vw;
    height: 70vh;
    color: white;
    padding: 10px;
  }
}

@media (max-width: 768px) {
  .main {
    background: rgba(0, 0, 0, 0.8);
    width: 80vw;
    height: 100%;
    color: white;
    padding: 10px;
  }
}

.donation {
  height: 437px !important;
}

.footer {
  display: flex;
  margin: 10px auto;
  align-items: center;
}

.footer-image {
  max-width: 80px;
  max-height: 80px;
  background-color: #272727;
  border: 5px solid #272727;
  border-radius: 50%;
  height: auto; /* So the image doesn't distort */
}

</style>