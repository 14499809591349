<template>
  <v-app>
<!--    <v-app-bar-->
<!--      app-->
<!--      color="primary"-->
<!--      dark-->
<!--    >-->
<!---->
<!--      <v-spacer></v-spacer>-->
<!---->
<!--    </v-app-bar>-->

    <v-main>
      <NetryBy/>
    </v-main>
  </v-app>
</template>

<script>
import NetryBy from './components/NetryBy';

export default {
  name: 'App',

  components: {
    NetryBy,
  },

  data: () => ({
    //
  }),
};
</script>
